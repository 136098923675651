import SocialsStrip from "../../components/socialsStrip/SocialsStrip";
import { FaLinkedin } from "react-icons/fa";
import { FaGithubSquare } from "react-icons/fa";
import {
  SocialMediaBannerStyles,
  LinksWrapper,
} from "./SocialMediaBannerStyles";
import img from "../../images/rjlinkedin.jpg";
import placeholder from "../../images/placeholder.jpg";
import ProgressiveImg from "../../components/ProgressiveImg";

const jamesLinkedIn = "https://www.linkedin.com/in/james-dudhi-price/";
const jamesGitHub = "https://github.com/jamesdpli";
const richelleLinkedIn = "https://www.linkedin.com/in/richelle-xavier/";
const richelleGitHub = "https://github.com/rnxavier";

const SocialMediaBanner = () => {
  return (
    <SocialMediaBannerStyles>
      <SocialsStrip linkedin={jamesLinkedIn} github={jamesGitHub} />
      <LinksWrapper>
        <h4>Check us out 👀</h4>
        <div>
          <p>Richelle</p>

          <a
            href={richelleLinkedIn}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="richelle-linkedin"
          >
            <FaLinkedin color="#0072b1" />
          </a>

          <a
            href={richelleGitHub}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="richelle-github"
          >
            <FaGithubSquare color="black" />
          </a>

          <p>James</p>

          <a
            href={jamesLinkedIn}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="james-linkedin"
          >
            <FaLinkedin color="#0072b1" />
          </a>

          <a
            href={jamesGitHub}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="james-github"
          >
            <FaGithubSquare color="black" />
          </a>
        </div>
      </LinksWrapper>

      <ProgressiveImg
        src={img}
        placeholderSrc={placeholder}
        width="50%"
        height="40%"
      />

      <SocialsStrip linkedin={richelleLinkedIn} github={richelleGitHub} />
    </SocialMediaBannerStyles>
  );
};

export default SocialMediaBanner;
