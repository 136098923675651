import { FaLinkedin } from "react-icons/fa";
import { FaGithubSquare } from "react-icons/fa";
import { SocialsStripStyles } from "./SocialsStripStyles";

const SocialsStrip = ({ linkedin, github }) => {
  return (
    <SocialsStripStyles>
      <a href={linkedin} target="_blank" rel="noopener noreferrer">
        <FaLinkedin color="#0072b1" />
      </a>

      <a href={github} target="_blank" rel="noopener noreferrer">
        <FaGithubSquare color="black" />
      </a>
    </SocialsStripStyles>
  );
};

export default SocialsStrip;
