import { Card, Col, Row } from "react-bootstrap";
import {
  AboutStyles,
  USPStyles,
  CarouselStyles,
  USPContainer,
} from "./AboutStyles";
import { FcAndroidOs } from "react-icons/fc";
import { DiHtml5, DiCss3, DiReact, DiJava } from "react-icons/di";
import { SiTypescript } from "react-icons/si";

import { SiSpring, SiKotlin } from "react-icons/si";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import CarouselComponent from "../../components/carousel/CarouselComponent";

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <AboutStyles>
      <h1>We Are RJDevs</h1>

      <p>
        Developers with a passion for coding and designing beautiful apps ✨
      </p>

      <p>
        We are open to taking on new projects! Have a look at some of our
        previous work and get in touch if you are interested.
      </p>

      <USPContainer>
        <USPStyles>
          <h2>Why Choose Us?</h2>
          <ul className="wide-screen">
            <li>Affordable, fast delivery and great communication</li>
            <li>Bespoke product that suits your needs</li>
            <li>
              Projects developed using a template provided by you, or we can
              design a UI and tailor it to your preference
            </li>
          </ul>
          <ul className="mobile-screen">
            <li>
              <span>&#x2713; </span> Affordable, fast delivery and great
              communication
            </li>
            <li>
              <span>&#x2713; </span> Bespoke product that suits your needs
            </li>
            <li>
              <span>&#x2713; </span> Projects developed using a template
              provided by you, or we can design a UI and tailor it to your
              preference
            </li>
          </ul>
        </USPStyles>

        <CarouselStyles>
          <CarouselComponent />
        </CarouselStyles>
      </USPContainer>

      <hr
        className="line"
        style={{ margin: "30px auto 50px auto", width: "100%" }}
      />

      <h2>Tech Stack 🚀</h2>
      <Row xs={1} md={3} className="g-4">
        <Col>
          <Card
            data-aos="zoom-in-right"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              borderColor: "rgb(255, 255, 255, 0.9)",
            }}
          >
            <Card.Img variant="top" src="" />
            <Card.Body>
              <Card.Title
                style={{
                  marginBottom: "20px",
                  color: "#606060",
                  textDecoration: "underline",
                  fontWeight: "400",
                  fontSize: "1.1rem",
                }}
              >
                Web
              </Card.Title>
              <Card.Text>
                <p>
                  <DiHtml5
                    style={{ color: "red", width: "30px", height: "30px" }}
                  />
                  HTML
                </p>
                <p>
                  <DiCss3
                    style={{ color: "blue", width: "30px", height: "30px" }}
                  />
                  CSS
                </p>
                <p>
                  <SiTypescript
                    style={{ color: "#3178c6", width: "25px", height: "25px" }}
                  />{" "}
                  TypeScript
                </p>
                <p>
                  <DiReact
                    style={{
                      color: "lightblue",
                      width: "35px",
                      height: "35px",
                    }}
                  />
                  React
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            data-aos="zoom-in"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              borderColor: "rgb(255, 255, 255, 0.9)",
            }}
          >
            <Card.Img variant="top" src="" />
            <Card.Body>
              <Card.Title
                style={{
                  marginBottom: "20px",
                  color: "#606060",
                  textDecoration: "underline",
                  fontWeight: "400",
                  fontSize: "1.1rem",
                }}
              >
                Backend
              </Card.Title>
              <Card.Text>
                <p>
                  <DiJava
                    style={{ color: "blue", width: "30px", height: "30px" }}
                  />
                  Java
                </p>
                <p>
                  <SiSpring
                    style={{
                      color: "lightgreen",
                      width: "25px",
                      height: "25px",
                      marginRight: "3px",
                    }}
                  />
                  Spring
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            data-aos="zoom-in-left"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              borderColor: "rgb(255, 255, 255, 0.9)",
            }}
          >
            <Card.Img variant="top" src="" />
            <Card.Body>
              <Card.Title
                style={{
                  marginBottom: "20px",
                  color: "#606060",
                  textDecoration: "underline",
                  fontWeight: "400",
                  fontSize: "1.1rem",
                }}
              >
                Mobile
              </Card.Title>
              <Card.Text>
                <p>
                  <SiKotlin
                    style={{
                      color: "purple",
                      width: "20px",
                      height: "20px",
                      marginRight: "3px",
                    }}
                  />
                  Kotlin
                </p>
                <p>
                  <FcAndroidOs style={{ width: "30px", height: "30px" }} />
                  Android Development
                </p>
                <p>
                  <DiReact
                    style={{
                      color: "lightblue",
                      width: "35px",
                      height: "35px",
                    }}
                  />
                  React Native
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </AboutStyles>
  );
};

export default About;
